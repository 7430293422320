import React, { useState, useEffect } from "react";
import { useAppState } from "@state/state";
import classNames from "classnames";
import { navigate } from "gatsby";
import { SearchFilterBar } from "@modules/search/index";
import { CurriculumGroup } from "@modules/curriculum/index";
import { SEO, RegisterCTA } from "@common";
import { Text, Button, RichText, SVG, Section, Container } from "@ui";

import { t } from "@translations";

/* eslint react/prop-types: 0 */

const CollectionSingle = ({ data, location }) => {
  const [rendered, setRendered] = useState(false);
  useEffect(() => setRendered(true), []);

  const [{ search, breakpoints }, dispatch] = useAppState();
  const { uid, title, description, illustration, materials } = data;

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue",
        showSearch: true,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });

    dispatch({ type: "deSelectCard" });
  }, []);

  // Set list view on mobile
  const { sm } = breakpoints;
  const { view } = search;
  useEffect(() => {
    if (sm === false && view !== "list") {
      dispatch({
        type: "changeView",
        view: "list",
      });
    }
  }, [sm]);

  const { prevPath = "" } = location.state || {};
  const { type } = search;
  const goBack = () => {
    if (prevPath.indexOf("/curriculum") > -1 && type === "collections") {
      window.history.back();
    } else {
      navigate("/curriculum");
    }
    if (type !== "collections") {
      dispatch({
        type: "setFilters",
        filters: {
          ...search.filters,
          type: "collections",
        },
      });
    }
  };

  return (
    <>
      <SEO title={title} description={description.text} />

      <Section hasPaddingBottom={false} className="bg-blue">
        <Container className="relative z-10 flex items-end text-white mt-12">
          <div className="flex flex-col flex-1">
            <div>
              <Button
                text={t("all collections")}
                preset="bordered"
                size="sm"
                className="hover:bg-white hover:text-blue hover:border-white"
                iconName="Arrow"
                iconClassName="w-4 h-3"
                onClick={goBack}
              />
            </div>

            <div className="my-6 md:mt-12 md:mb-0 lg:mt-24">
              <Text preset="h1">{title}</Text>

              <div className="md:hidden my-6">
                <div
                  className="w-full relative"
                  style={{ paddingBottom: `${(383 / 360) * 100}%` }}
                >
                  <span
                    className={classNames({
                      "absolute top-0 right-0 left-0 bottom-0 transition-slow-expo": true,
                      "opacity-0 illo": !rendered,
                      "opacity-100": rendered,
                    })}
                  >
                    <SVG svg={illustration.text} />
                  </span>
                </div>
              </div>

              <RichText
                html={description.html}
                className="leading-wiggly mt-8 mb-6 sm:mb-8 md:mb-12"
              />
            </div>
          </div>

          <div className="hidden md:block w-1/2 pl-24">
            <div
              className="w-full relative"
              style={{ paddingBottom: `${(383 / 360) * 100}%` }}
            >
              <span
                className={classNames({
                  "absolute top-0 right-0 left-0 bottom-0 transition-slow-expo": true,
                  "opacity-0 illo": !rendered,
                  "opacity-100": rendered,
                })}
              >
                <SVG
                  svg={illustration.text}
                  setExplicitHeight
                  className="h-full"
                />
              </span>
            </div>
          </div>
        </Container>
      </Section>

      <Section
        hasPaddingTop={false}
        hasPaddingBottom={false}
        allowOverflow
        className="z-10"
      >
        <Container>
          <SearchFilterBar showSearchIfTypeSearchable={false} />
        </Container>
      </Section>

      <Section size="sm" allowOverflow>
        <Container
          className={classNames({
            "relative w-full": true,
            "md:flex": view === "list",
          })}
        >
          <div
            className={classNames({
              "flex flex-wrap -mx-3": view === "cards",
              "md:w-2/3": view === "list",
            })}
          >
            <CurriculumGroup
              items={materials}
              typeOverride="singles"
              collectionUid={uid}
            />
          </div>

          <RegisterCTA />
        </Container>
      </Section>
    </>
  );
};

export default CollectionSingle;
