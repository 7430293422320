import React from "react";
import { graphql } from "gatsby";
import { keysToCamel } from "@utils";
import CollectionSingle from "../../pageTemplates/CollectionSingle/CollectionSingle";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import mergePrismicPreview from "../mergePrismicPreview";

/* eslint react/prop-types: 0 */

const dataResolver = ({ prismicCollections }) => {
  const { uid, data } = prismicCollections;
  const { materials, ...dataRest } = data;

  const resolvedMaterials = resolveEmbeddedDocument(materials);

  const resolved = {
    data: {
      uid,
      ...dataRest,
      materials: resolvedMaterials.map(item => ({
        ...item,
        color: resolveEmbeddedDocument(item.cor_question).color,
        corQuestion: resolveEmbeddedDocument(item.cor_question).id,
      })),
    },
  };

  return keysToCamel(resolved);
};

const CollectionQuery = ({ data: _data, location }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <CollectionSingle {...data} location={location} />;
};

export default CollectionQuery;

export const query = graphql`
  query($uid: String!) {
    prismicCollections(uid: { eq: $uid }) {
      uid
      data {
        title
        description {
          text
          html
        }
        illustration {
          text
        }
        materials {
          material {
            id
            document {
              ... on PrismicLessons {
                id
                uid
                type
                data {
                  title
                  illustration {
                    text
                  }
                  summary {
                    html
                  }
                  cor_question {
                    document {
                      ... on PrismicCorQuestions {
                        id
                        data {
                          color
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicAssessments {
                id
                uid
                type
                data {
                  title
                  illustration {
                    text
                  }
                  summary {
                    html
                  }
                  cor_question {
                    document {
                      ... on PrismicCorQuestions {
                        id
                        data {
                          color
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
